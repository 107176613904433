<template>
	<Sect class="layout__sect layout__sect--contacts">
		<video class="layout__sect-bg" autoplay="" loop="" muted="" playsinline="">
			<source src="../../../assets/media/file4.mp4" type="video/mp4" />
		</video>

		<div class="block">
			<ul class="block__cols">
				<li class="block__col">
					<h2 class="block__title">Contact With Us</h2>
					<address>
						Address: 5 University Street, Geneva, Switzerland 1205
					</address>
					<a href="mailto:info@dma-solutions.org">info@dma-solutions.org</a>
				</li>
			</ul>
		</div>

		<img
			class="layout__sect-img"
			src="../../../assets/img/contacts.webp"
			alt=""
		/>
	</Sect>
</template>

<script>
import Sect from '../../../components/Section.vue'
export default {
	name: 'Contacts',
	components: {
		Sect,
	},
}
</script>

<style lang="scss" scoped>
.block {
	max-width: 940px;
	margin-left: auto;
	margin-right: auto;
	color: #fff;

	a {
		font-size: 20px;
		margin-top: 14px;
	}
}

.block__cols {
	display: flex;
	margin: 0 -15px;

	@media screen and (max-width: 767px) {
    flex-direction: column;
  }

}

.block__col {
	flex: 1;
	padding: 0 15px;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.block__title {
	font-weight: 700;
	font-size: 40px;
	margin-bottom: 20px;
}

.form--second {
	max-width: 100%;
}

.form__input {
	background: #fff;
}
</style>
