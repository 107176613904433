<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "Container"
  }
</script>

<style lang="scss" scoped>
  .container {
    max-width: 1170px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }
</style>