<template>
	<section>
		<slot></slot>
	</section>
</template>

<script>
export default {
	name: 'Sect',
}
</script>

<style lang="scss">
.layout__sect {
	padding: 80px 0;

	&-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: -1;
	}
}

.layout__sect-cols {
	display: flex;
	padding: 0 80px;

	@media screen and (max-width: 1199px) {
		padding: 0 15px;
	}
}

.layout__sect-col {
	flex: 1;

	@media screen and (max-width: 767px) {
		&:last-child {
			display: none;
		}
	}
}

.layout__sect-title {
	font-weight: 700;
	font-size: 72px;
	margin-bottom: 20px;
}

.layout__sect-subtitle {
	font-size: 20px;
}

.layout__sect--main {
	position: relative;
	overflow: hidden;
	color: #fff;
	min-height: 660px;
	display: flex;
	align-items: center;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: -1;
	}

	.layout__sect-title {
		max-width: 800px;
	}
}

.layout__sect--smallvideo {
	color: #fff;
	position: relative;
	overflow: hidden;
	min-height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.1);
		z-index: -1;
	}

	.layout__sect-contains {
		min-height: auto;
		background-color: transparent;

		@media screen and (max-width: 767px) {
			padding: 0!important;
		}

		.layout__sect-text {
			max-width: 900px;
		}
	}
}

.layout__sect--pink {
	background-color: #f4d2d3;
}

.layout__sect-contains {
	padding: 15px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	background-color: #fff;
	min-height: 580px;

	@media screen and (max-width: 767px) {
		min-height: auto;
		padding: 50px 15px;
	}

	.layout__sect-title {
		font-size: 40px;
	}

	.layout__sect-subtitle {
		margin-bottom: 30px;
	}

	.layout__sect-text {
		max-width: 470px;

		@media screen and (max-width: 767px) {
			padding: 0!important;
		}
	}
}

.layout__sect-text {
	font-size: 14px;
	line-height: 2;
}

.layout__sect--contacts {
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: -1;
	}
}

.layout__sect-img {
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
}
</style>
