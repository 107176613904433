<template>
	<router-link v-bind:to="{ name: 'homepage' }" class="logo">
		<img src="../assets/img/logo-s.webp" width="207" height="79" />
	</router-link>
</template>

<script>
export default {
	name: 'Logo',
}
</script>

<style lang="scss" scoped>
.logo {
	display: block;
}

@media screen and (max-width: 767px) {
	.logo img {
		width: 100px;
		height: auto;
	}
}
</style>
