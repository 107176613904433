import Homepage from './pages/Homepage/Homepage.vue'
import Servicepage from './pages/Servicepage.vue'
import Aboutpage from './pages/Aboutpage.vue'
import VueRouter from "vue-router";

const routes = [
  {
    path: '/',
    component: Homepage,
    name: 'homepage'
  },
  {
    path: '/our-service',
    component: Servicepage,
    name: 'servicepage'
  },
  {
    path: '/about',
    component: Aboutpage,
    name: 'aboutpage'
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router