<template>
	<main class="layout">
		<section class="layout__sect">
			<div class="layout__sect-cols">
				<div class="layout__sect-col">
					<img
						src="../assets/img/about.webp"
						alt=""
						class="object-fit is--cover"
					/>
				</div>
				<div class="layout__sect-col layout__sect-text">
					<h1>Get To Know Us</h1>
					<p>
						Since 2008, we have been helping our clients grow through our
						unparalleled services. We work shoulder to shoulder with our clients
						so that we can build creative and strategic solutions to their
						challenges.
					</p>

					<p>
						Digital Marketing offers a comprehensive approach with the speed and
						agility you need to propel your business forward. Our research-based
						strategy means we kick-off our work with you by immersing ourselves
						in your industry, your vision and your culture. We take care of your
						creative needs so that you can focus on running your business; we
						work together in order to help you grow.
					</p>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
	name: 'Aboutpage',
}
</script>

<style lang="scss" scoped>
.layout__sect {
	padding: 80px;
	background-color: #12139c;
	height: 100%;

	@media screen and (max-width: 1199px) {
		padding-left: 0;
		padding-right: 0;
	}
}

.layout__sect-cols {
	min-height: 700px;
	display: flex;

	@media screen and (max-width: 1199px) {
		min-height: auto;
	}

	@media screen and (max-width: 767px) {
		padding: 0;
	}
}

.layout__sect-col {
	flex: 1;

	@media screen and (max-width: 767px) {
		display: none;
	}
}

.layout__sect-text {
	text-align: center;
	color: #12139c;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px;

	@media screen and (max-width: 767px) {
		display: block;
		padding-left: 10px;
		padding-right: 10px;
	}

	& > *:not(:last-child) {
		margin-bottom: 30px;
	}

	p {
		max-width: 500px;

		@media screen and (max-width: 767px) {
			max-width: 100%;
			font-size: 16px;
		}
	}

	h1 {
		font-size: 34px;
		font-weight: 700;

		@media screen and (max-width: 767px) {
			font-size: 28px!important;
			margin-bottom: 15px!important;
		}
	}
}
</style>
