<template>
	<main class="layout">
		<Main/>
		<Second/>
		<Third/>
		<Slider/>
		<Contacts/>
	</main>
</template>

<script>
import Main from "../../pages/Homepage/Sections/Main.vue";
import Second from "../../pages/Homepage/Sections/Second.vue";
import Third from "../../pages/Homepage/Sections/Third.vue";
import Contacts from "../../pages/Homepage/Sections/Contacts.vue";
import Slider from "../../pages/Homepage/Sections/Slider.vue";
export default {
	name: 'Homepage',
	components: {
		Main,
		Second,
		Third,
		Contacts,
		Slider
	},
}
</script>

<style lang="scss" scoped></style>
