<template>
	<Sect class="layout__sect layout__sect--smallvideo">
		<video class="layout__sect-bg" autoplay="" loop="" muted="" playsinline="">
			<source src="../../../assets/media/file3.mp4" type="video/mp4" />
		</video>
		<div class="layout__sect-contains">
			<h2 class="layout__sect-title">
				What We Do
			</h2>
			<p class="layout__sect-subtitle">Inspire and Engage</p>

			<p class="layout__sect-text">
				We offer a wide range of professional Digital Marketing Agency services.
				We’ll work together with you to create an individual package that best
				suits your needs. Rise through the ranks from our tailored strategies
				that truly work. We’ll help you to get to where you want to be, and push
				you to soar even further than you ever imagined was possible.
			</p>
		</div>
	</Sect>
</template>

<script>
import Sect from '../../../components/Section.vue'
export default {
  name: "Third",
  components: {
    Sect
  }
}
</script>

<style lang="scss" scoped></style>
