<template>
  <a v-bind:href="href">
    <slot></slot>
  </a>
</template>

<script>
  export default {
    name: 'Link',
    props: {
      href: {
        type: String,
        default: '#'
      }
    }
  }
</script>