<template>
	<main class="layout">
		<section class="layout__sect layout__sect--dark">
			<h1 class="layout__sect-title">
				The Full-Service Agency That Does It All
			</h1>
			<p class="layout__sect-text">Get Ready to Grow</p>

			<ul class="list">
				<li class="list__item">
					<div class="block-inf">
						<div class="block__inf-img">
							<img
								src="../assets/img/block1.webp"
								alt=""
								class="object-fit is--cover"
							/>
						</div>

						<div class="block__inf-contains">
							<h2>Brand Strategy</h2>
							<p>Results You’ll Love</p>
						</div>
					</div>
				</li>

				<li class="list__item">
					<div class="block-inf">
						<div class="block__inf-img">
							<img
								src="../assets/img/block2.webp"
								alt=""
								class="object-fit is--cover"
							/>
						</div>

						<div class="block__inf-contains">
							<h2>Content Marketing</h2>
							<p>We Do It Right</p>
						</div>
					</div>
				</li>

				<li class="list__item">
					<div class="block-inf">
						<div class="block__inf-img">
							<img
								src="../assets/img/block2.webp"
								alt=""
								class="object-fit is--cover"
							/>
						</div>

						<div class="block__inf-contains">
							<h2>Social Media Marketing</h2>
							<p>Get Things Done</p>
						</div>
					</div>
				</li>

				<li class="list__item">
					<div class="block-inf">
						<div class="block__inf-img">
							<img
								src="../assets/img/block4.webp"
								alt=""
								class="object-fit is--cover"
							/>
						</div>

						<div class="block__inf-contains">
							<h2>Search Engine Optimization (SEO)</h2>
							<p>Get Things Done</p>
						</div>
					</div>
				</li>

				<li class="list__item">
					<div class="block-inf">
						<div class="block__inf-img">
							<img
								src="../assets/img/block5.webp"
								alt=""
								class="object-fit is--cover"
							/>
						</div>

						<div class="block__inf-contains">
							<h2>User Experience Design</h2>
							<p>Let Us Handle Everything</p>
						</div>
					</div>
				</li>

				<li class="list__item">
					<div class="block-inf">
						<div class="block__inf-img">
							<img
								src="../assets/img/block6.webp"
								alt=""
								class="object-fit is--cover"
							/>
						</div>

						<div class="block__inf-contains">
							<h2>Web Analytics</h2>
							<p>Results, Delivered</p>
						</div>
					</div>
				</li>

				<li class="list__item">
					<div class="block-inf">
						<div class="block__inf-img">
							<img
								src="../assets/img/block7.webp"
								alt=""
								class="object-fit is--cover"
							/>
						</div>

						<div class="block__inf-contains">
							<h2>Pay Per Click (PPC)</h2>
							<p>We’ll Take Care of That</p>
						</div>
					</div>
				</li>

				<li class="list__item">
					<div class="block-inf">
						<div class="block__inf-img">
							<img
								src="../assets/img/block7.webp"
								alt=""
								class="object-fit is--cover"
							/>
						</div>

						<div class="block__inf-contains">
							<h2>Email Marketing</h2>
							<p>Results You’ll Love</p>
						</div>
					</div>
				</li>
			</ul>
		</section>

		<section class="layout__sect layout__sect--pink">
			<h2 class="layout__sect-title">
				Advance your Business with Digital Marketing
			</h2>
			<p class="layout__sect-text">We Keep Clients Happy</p>

			<VueSlickCarousel v-bind="settings" class="carousel carousel--fullwidth">
				<div class="carousel-slide">
					<img src="../assets/img/1.jpg" alt="" />
				</div>

				<div class="carousel-slide">
					<img src="../assets/img/2.jpg" alt="" />
				</div>

				<div class="carousel-slide">
					<img src="../assets/img/3.jpg" alt="" />
				</div>

				<div class="carousel-slide">
					<img src="../assets/img/4.jpg" alt="" />
				</div>
			</VueSlickCarousel>
		</section>

		<section class="layout__sect">
			<h2 class="layout__sect-title">
				What We Do
			</h2>
			<p class="layout__sect-text">Inspire and Engage</p>

			<ul class="block-list">
				<li class="block-list__item">
					<div class="block-list__img">
						<img
							src="../assets/img/slide1.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>

					<div class="block-list__text">
						<h2>Brand Strategy</h2>
						<p>
							Our Brand Strategy services will put you at the head of the class.
							We create smart, innovative communication channels that will take
							you to where you need to be. With our smart ideas, stylish work,
							and successful communication, we will infuse your entire brand
							with a unified strategy, voice and look to maximize your return on
							investment.
						</p>
					</div>
				</li>

				<li class="block-list__item">
					<div class="block-list__img">
						<img
							src="../assets/img/slide2.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>

					<div class="block-list__text">
						<h2>Content Marketing</h2>
						<p>
							Looking for Copywriting services? Leave it to us. We know just
							what it takes to differentiate you from the crowded and
							competitive market in your specific industry. With our team of
							experts, no task is too large for us to accomplish. We are driven,
							dedicated, and will work tirelessly to get the results that you
							deserve.
						</p>
					</div>
				</li>

				<li class="block-list__item">
					<div class="block-list__img">
						<img
							src="../assets/img/slide3.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>

					<div class="block-list__text">
						<h2>Search Engine Optimization (SEO)</h2>
						<p>
							We specialize in expert SEO services. Our SEO services will give
							your businesses that extra adrenaline shot it needs to get the
							ball rolling and take action with all creative aspects of any
							project, large or small scale. Count on our team to handle your
							SEO project like pros. We are here to help you succeed.
						</p>
					</div>
				</li>

				<li class="block-list__item">
					<div class="block-list__img">
						<img
							src="../assets/img/slide4.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>

					<div class="block-list__text">
						<h2>Pay Per Click (PPC)</h2>
						<p>
							We specialize in professional Marketing services. We deliver
							impactful, sophisticated results to each and every one of our
							clients, making them stand out in the creative world. We are
							smart, we are fast, and we get the job done just how you
							envisioned. See how our services can truly benefit you today.
						</p>
					</div>
				</li>

				<li class="block-list__item">
					<div class="block-list__img">
						<img
							src="../assets/img/slide5.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>

					<div class="block-list__text">
						<h2>Social Media Marketing</h2>
						<p>
							Our Design services will put you at the head of the class. We
							create smart, innovative communication channels that will take you
							to where you need to be. With our smart ideas, stylish work, and
							successful communication, we will infuse your entire brand with a
							unified strategy, voice and look to maximize your return on
							investment.
						</p>
					</div>
				</li>

				<li class="block-list__item">
					<div class="block-list__img">
						<img
							src="../assets/img/slide6.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>

					<div class="block-list__text">
						<h2>User Experience Design</h2>
						<p>
							Our Design services will put you at the head of the class. We
							create smart, innovative communication channels that will take you
							to where you need to be. With our smart ideas, stylish work, and
							successful communication, we will infuse your entire brand with a
							unified strategy, voice and look to maximize your return on
							investment.
						</p>
					</div>
				</li>

				<li class="block-list__item">
					<div class="block-list__img">
						<img
							src="../assets/img/slide7.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>

					<div class="block-list__text">
						<h2>Web Analytics</h2>
						<p>
							Our Brand Strategy services are best-in-class. The Brand Strategy
							work we offer enables your business to grow and helps increase
							your exposure in your business vertical. We begin by working with
							you to define project goals that focus on reach, engagement, and
							conversion. We then map out a plan to achieve these goals. This is
							what we live for: merging creativity and technology in a way that
							brings your business more success than you ever thought was
							possible.
						</p>
					</div>
				</li>

				<li class="block-list__item">
					<div class="block-list__img">
						<img
							src="../assets/img/slide8.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>

					<div class="block-list__text">
						<h2>Email Marketing</h2>
						<p>
							We specialize in expert SEO services. Our SEO services will give
							your businesses that extra adrenaline shot it needs to get the
							ball rolling and take action with all creative aspects of any
							project, large or small scale. Count on our team to handle your
							SEO project like pros. We are here to help you succeed.
						</p>
					</div>
				</li>
			</ul>
		</section>
	</main>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import VueSlickCarousel from 'vue-slick-carousel'
export default {
	name: 'Servicepage',
	data() {
		return {
			settings: {
				dots: true,
				edgeFriction: 0.35,
				infinite: false,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				swipe: false,
				arrows: false,
				autoplay: 2000,
				pauseOnHover: true,
			},
		}
	},
	components: {
		VueSlickCarousel,
	},
}
</script>

<style lang="scss">
.block-list {
	margin: 40px -15px 0;
}

.block-list__item {
	height: 580px;
	display: flex;

	@media screen and (max-width: 991px) {
		height: auto;
		margin: 30px 0;
		position: relative;
	}
}

.block-list__item:nth-child(even) {
	.block-list__img {
		order: 1;
	}
}

.block-list__img {
	@media screen and (max-width: 991px) {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.8);
		}
	}
}

.block-list__img,
.block-list__text {
	width: 100%;
}

.block-list__text {
	width: calc(100% - 30px);
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #12139c;
	color: #fff;

	@media screen and (max-width: 991px) {
		width: 100%;
		padding: 50px 15px;

		* {
			z-index: 1;
		}
	}

	h2 {
		width: 100%;
		font-weight: 700;
		margin-bottom: 20px;
		font-size: 26px;
	}

	p {
		line-height: 2;
	}

	& > * {
		max-width: 460px;
	}
}

.carousel--fullwidth {
	padding: 0;
	height: 575px;
	margin: 0 -15px;

	@media screen and (max-width: 1199px) {
		height: auto !important;
	}

	.slick-slide {
		padding: 0 !important;
		overflow: hidden;
		height: 575px !important;

		@media screen and (max-width: 1199px) {
			height: auto !important;
		}
	}

	.slick-dots {
		bottom: 10px !important;
	}

	.carousel-slide {
		height: 100%;
		min-height: auto;
		max-width: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.layout__sect--pink {
	background-color: #f4d2d3;
	padding-bottom: 0;

	.layout__sect-title {
		font-size: 72px;
		max-width: 768px;
		margin-left: auto;
		margin-right: auto;
		line-height: 1.1;

		@media screen and (max-width: 1199px) {
			font-size: 40px;
		}
	}

	.layout__sect-text {
		padding-bottom: 40px;
	}
}
.list {
	display: flex;
	flex-wrap: wrap;
	margin: -30px -15px;
	padding: 80px;

	@media screen and (max-width: 1199px) {
		padding: 80px 0;
	}

	&__item {
		width: calc(100% / 4);
		padding: 30px 15px;

		@media screen and (max-width: 1199px) {
			width: calc(100% / 3);
		}

		@media screen and (max-width: 767px) {
			width: calc(100% / 2);
		}

		@media screen and (max-width: 500px) {
			width: calc(100% / 1);
		}
	}
}

.block-inf {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.block__inf-contains {
	margin-top: 30px;
	text-align: center;

	h2 {
		font-size: 22px;
		font-weight: 700;
		margin-bottom: 20px;
	}

	p {
		font-size: 18px;
	}
}

.block__inf-img {
	width: 180px;
	height: 180px;
	overflow: hidden;
	border-radius: 50%;
}

.layout__sect--dark {
	background-color: #12139c;
	color: #fff;
}

.layout__sect {
	padding-left: 15px;
	padding-right: 15px;
}

.layout__sect-title {
	text-align: center;
	font-size: 40px;
}

.layout__sect-text {
	text-align: center;
	font-size: 18px;
}
</style>
