<template>
	<section class="layout__sect">
		<VueSlickCarousel v-bind="settings" class="carousel">
			<template #prevArrow>
				<button class="carousel-arrow carousel--prev">
					<svg viewBox="0 0 21 41" width="15" height="30">
						<path d="M20.3 40.8L0 20.5 20.3.2l.7.7L1.3 20.5 21 40.1z"></path>
					</svg>
				</button>
			</template>

			<template #nextArrow>
				<button class="carousel-arrow carousel--next">
					<svg viewBox="0 0 21 41" width="15" height="30">
						<path d="M20.3 40.8L0 20.5 20.3.2l.7.7L1.3 20.5 21 40.1z"></path>
					</svg>
				</button>
			</template>

			<div class="carousel-slide">
				<div class="carousel__block">
					<div class="carousel__block-text">
						<p class="carousel__title">Brand Strategy</p>
						<p class="carousel__text">Let Us Handle Everything</p>

						<Link class="carousel__link">Get in Touch</Link>
					</div>

					<div class="carousel__block-img">
						<img
							src="../../../assets/img/slide1.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>
				</div>
			</div>

			<div class="carousel-slide">
				<div class="carousel__block">
					<div class="carousel__block-text">
						<p class="carousel__title">Search Engine Optimization (SEO)</p>
						<p class="carousel__text">Results, Delivered</p>

						<Link class="carousel__link">Get in Touch</Link>
					</div>

					<div class="carousel__block-img">
						<img
							src="../../../assets/img/slide2.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>
				</div>
			</div>

			<div class="carousel-slide">
				<div class="carousel__block">
					<div class="carousel__block-text">
						<p class="carousel__title">Social Media Marketing</p>
						<p class="carousel__text">We’ll Take Care of That</p>

						<Link class="carousel__link">Get in Touch</Link>
					</div>

					<div class="carousel__block-img">
						<img
							src="../../../assets/img/slide3.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>
				</div>
			</div>

			<div class="carousel-slide">
				<div class="carousel__block">
					<div class="carousel__block-text">
						<p class="carousel__title">Pay Per Click (PPC)</p>
						<p class="carousel__text">Get Things Done</p>

						<Link class="carousel__link">Get in Touch</Link>
					</div>

					<div class="carousel__block-img">
						<img
							src="../../../assets/img/slide4.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>
				</div>
			</div>

			<div class="carousel-slide">
				<div class="carousel__block">
					<div class="carousel__block-text">
						<p class="carousel__title">User Experience Design</p>
						<p class="carousel__text">Let Us Handle Everything</p>

						<Link class="carousel__link">Get in Touch</Link>
					</div>

					<div class="carousel__block-img">
						<img
							src="../../../assets/img/slide5.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>
				</div>
			</div>

			<div class="carousel-slide">
				<div class="carousel__block">
					<div class="carousel__block-text">
						<p class="carousel__title">Content Marketing</p>
						<p class="carousel__text">Results, Delivered</p>

						<Link class="carousel__link">Get in Touch</Link>
					</div>

					<div class="carousel__block-img">
						<img
							src="../../../assets/img/slide6.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>
				</div>
			</div>

			<div class="carousel-slide">
				<div class="carousel__block">
					<div class="carousel__block-text">
						<p class="carousel__title">Email Marketing</p>
						<p class="carousel__text">We’ll Take Care of That</p>

						<Link class="carousel__link">Get in Touch</Link>
					</div>

					<div class="carousel__block-img">
						<img
							src="../../../assets/img/slide7.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>
				</div>
			</div>

			<div class="carousel-slide">
				<div class="carousel__block">
					<div class="carousel__block-text">
						<p class="carousel__title">Web Analytics</p>
						<p class="carousel__text">Results You’ll Love</p>

						<Link class="carousel__link">Get in Touch</Link>
					</div>

					<div class="carousel__block-img">
						<img
							src="../../../assets/img/slide8.webp"
							alt=""
							class="object-fit is--cover"
						/>
					</div>
				</div>
			</div>
		</VueSlickCarousel>
	</section>
</template>

<script>
import Link from '../../../components/Link.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import VueSlickCarousel from 'vue-slick-carousel'
export default {
	name: 'Slider',
	data() {
		return {
			settings: {
				dots: true,
				edgeFriction: 0.35,
				infinite: false,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				swipe: false,
				autoplay: 2000,
				pauseOnHover: true,
				responsive: [{
					breakpoint: 1200,
					settings: {
						arrows: false,
						swipe: true
					}
				}]
			},
		}
	},
	components: { VueSlickCarousel, Link },
}
</script>

<style lang="scss">
.carousel {
	margin-top: 40px;
	margin-bottom: 50px;
}

.slick-slide {
	padding: 0 120px;

	@media screen and (max-width: 1199px) {
		padding: 0 15px;
	}
}

.carousel__link {
	display: inline-block;
	padding: 10px 15px;
	background-color: #fff;
	border: 1px solid #2d2ea7;
	color: #2d2ea7;
	transition: all 0.2s ease;

	&:hover {
		background-color: #2d2ea7;
		color: #fff;
	}
}

.carousel-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	fill: #2d2ea7;
	z-index: 1;
}

.carousel-slide {
	max-width: 940px;
	margin: 0 auto;
	min-height: 400px;
	display: block !important;

	@media screen and (max-width: 1199px) {
		min-height: auto;
	}
}

.carousel__block-text {
	@media screen and (max-width: 767px) {
		padding-right: 0!important;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
}

.carousel__block-img {
	@media screen and (max-width: 767px) {
		display: none;
	}
}

.carousel--prev {
	left: 75px;
}

.carousel--next {
	right: 75px;
	transform: scaleX(-1);
}

.slick-dots {
	position: absolute;
	bottom: -50px;
	left: 0;
	right: 0;
	display: flex !important;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	li {
		display: flex;
		padding: 0 5px;
	}

	button {
		width: 6px;
		height: 6px;
		overflow: hidden;
		border-radius: 50%;
		padding: 0;
		border: 1px solid #2d2ea7;
		background-color: #2d2ea7;
		text-indent: -9999px;
	}

	.slick-active {
		button {
			background-color: #fff;
		}
	}
}

.carousel__block {
	display: flex;
	align-items: center;

	&-text {
		flex: 1;
		padding-right: 30px;
	}

	&-img {
		flex: 1;
	}
}

.carousel__title {
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 30px;
}

.carousel__text {
	font-size: 18px;
	margin-bottom: 30px;
}
</style>
