<template>
	<Sect class="layout__sect layout__sect--main">
		<video class="layout__sect-bg" autoplay="" loop="" muted="" playsinline="">
			<source src="../../../assets/media/file2.mp4" type="video/mp4" />
		</video>
		<Container>
			<h1 class="layout__sect-title">
				Digital Marketing Agency, Web Design, SEO Services, PPC Campaigns
			</h1>
			<p class="layout__sect-subtitle">Expert Strategists Who Deliver</p>
		</Container>
	</Sect>
</template>

<script>
import Sect from '../../../components/Section.vue'
import Container from '../../../components/Container.vue'
export default {
  name: "Main",
  components: {
    Sect,
		Container,
  }
}
</script>

<style lang="scss" scoped>
	.layout__sect-title {
		font-size: 71px;
		text-align: left;

		@media screen and (max-width: 1199px) {
			font-size: 40px;
		}

		@media screen and (max-width: 767px) {
			font-size: 32px;
		}
	}

	.layout__sect--main {
		@media screen and (max-width: 1199px) {
			min-height: 400px;
		}

		@media screen and (max-width: 767px) {
			padding-left: 0;
			padding-right: 0;
		}
	}
</style>
