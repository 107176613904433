<template>
	<Sect class="layout__sect layout__sect--pink">
		<div class="layout__sect-cols">
			<div class="layout__sect-col">
				<div class="layout__sect-contains">
					<h2 class="layout__sect-title">
						Who Are We?
					</h2>
					<p class="layout__sect-subtitle">Meeting Your Needs</p>

					<p class="layout__sect-text">
						Since our founding in 2008, our team of bright minded and creative
						thinkers has been helping brands find their unique values. We map
						out the specific needs of each client so they can easily connect
						with their target audience. Our business is helping you promote
						yours, and we are here to ensure your successful future.
					</p>
				</div>
			</div>
			<div class="layout__sect-col">
				<img
					src="../../../assets/img/img.webp"
					alt=""
					class="object-fit is--cover"
				/>
			</div>
		</div>
	</Sect>
</template>

<script>
import Sect from '../../../components/Section.vue'
export default {
	name: 'Second',
	components: {
		Sect,
	},
}
</script>

<style lang="scss" scoped>
.layout__sect--pink {
	padding: 80px 0;

	@media screen and (max-width: 1199px) {
		.layout__sect-title {
			font-size: 34px;
		}

		.layout__sect-text {
			margin-bottom: 0;
			font-size: 16px;
		}
	}
}
</style>
