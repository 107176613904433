<template>
	<header class="header">
		<video class="header__bg" autoplay="" loop="" muted="" playsinline="">
			<source src="../assets/media/headerBg.mp4" type="video/mp4" />
		</video>

		<Container>
			<ul class="header__items">
				<li class="header__item">
					<Logo />
				</li>

				<li class="header__item header__item-nav">
					<Nav />
				</li>
			</ul>
		</Container>
	</header>
</template>

<script>
import Logo from './Logo'
import Container from './Container'
import Nav from './Nav'
export default {
	name: 'Header',
	components: {
		Logo,
		Container,
		Nav,
	},
}
</script>

<style lang="scss" scoped>
.header {
	padding: 12px 0;
	box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.6),
		inset 0 -1px 1px rgba(0, 0, 0, 0.6), 0 0 5px rgba(0, 0, 0, 0.6);
	position: relative;
	overflow: hidden;

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: -1;
		object-position: top;
	}

	&__items {
		display: flex;
		align-items: center;
		margin: 0 -15px;

    @media screen and (max-width: 767px) {
			margin: 0 -10px;
		}
	}

	&__item {
		padding: 0 15px;

		@media screen and (max-width: 767px) {
			padding: 0 10px;
		}

		&-nav {
			margin-left: auto;
		}
	}
}
</style>
