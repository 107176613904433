<template>
	<nav class="nav">
		<ul class="nav__list">
			<li class="nav__item">
				<router-link v-bind:to="{ name: 'homepage' }" class="nav__link"
					>Home</router-link
				>
			</li>

			<li class="nav__item">
				<router-link v-bind:to="{ name: 'servicepage' }" class="nav__link"
					>Our Service</router-link
				>
			</li>

			<li class="nav__item">
				<router-link v-bind:to="{ name: 'aboutpage' }" class="nav__link"
					>About</router-link
				>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: 'Nav',
}
</script>

<style lang="scss" scoped>
.nav__list {
	display: flex;
	flex-wrap: wrap;
	margin: -10px;

	@media screen and (max-width: 767px) {
		margin: -5px;
	}
}
.nav__item {
	padding: 10px;

	@media screen and (max-width: 767px) {
		padding: 5px;
	}
}
.nav__link {
	font-size: 14px;
	color: rgba(18, 19, 156, 1);

	@media screen and (max-width: 767px) {
		font-size: 12px;
	}
}
</style>
